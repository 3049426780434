import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Courses,
  Home,
  Articles,
  Testimonials,
  Contact,
  About,
  FAQ,
  CourseDetails,
  Discover
} from "../pages";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}



const MainRoutes = () => {
  useScrollToTop(); // Use the custom hook here

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Courses" element={<Courses />} />
      <Route path="/Articles" element={<Articles />} />
      <Route path="/Testimonials" element={<Testimonials />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/About" element={<About />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/CourseDetails/:id" element={<CourseDetails />} />
      <Route path="/discover" element={<Discover />} />
    </Routes>
  );
}

export default MainRoutes;
