import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faPhone } from '@fortawesome/free-solid-svg-icons';

const AboutUs = () => {
    return (
        <div className="bg-gray-100 py-10 px-4 flex justify-center">
            <div className="max-w-[1024px] w-full flex flex-col md:flex-row justify-between items-start gap-10">
                {/* About Us Section */}
                <div className="md:w-2/3">
                    <h2 className="font-bold text-2xl mb-4">About Us</h2>
                    <p className="text-gray-600">
                        Sahasra Education is a language learning platform dedicated to making
                        language acquisition a soulful and enriching experience. Currently, we offer
                        expert Japanese language instruction, fostering a deep connection with the
                        language and culture. With plans to expand, we aim to offer a diverse range
                        of languages in the future, helping learners connect with cultures globally
                        and unlock new opportunities through language.
                    </p>
                </div>

                {/* Follow and Contact Us Section */}
                <div className="flex flex-col gap-4">
                    <div>
                        <h3 className="font-bold text-lg">Follow Us</h3>
                        <div className="flex gap-4 mt-2">
                            <a
                                href="https://www.youtube.com/@sahasraeducation"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faYoutube} className="text-red-500 text-2xl" />
                            </a>
                            <a
                                href="https://www.instagram.com/sahasraeducationofficial/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faInstagram} className="text-pink-500 text-2xl" />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/sahasraeducationofficial"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedin} className="text-blue-500 text-2xl" />
                            </a>
                        </div>
                    </div>

                    <div>
                        <h3 className="font-bold text-lg">Contact Us</h3>
                        <div className="flex gap-4 mt-2">
                            <a
                                href="https://wa.me/+919332355074"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faWhatsapp} className="text-green-500 text-2xl" />
                            </a>
                            <a href="tel:+919332355074" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faPhone} className="text-blue-500 text-2xl" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
