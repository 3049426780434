import React from 'react';
import { discoverCources } from '../../constraints';
import { Image, Button } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { icons } from '../../assets';
import AboutUs from './AboutUs';

const data = [
    {
        name: "Online Classes",
        img: icons.monitor
    },
    {
        name: "Lifetime Class Recordings",
        img: icons.video
    },
    {
        name: "Effective Mock Tests",
        img: icons.check
    }
];

const Discover = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-white px-4 py-10 flex justify-center">
                <div className="max-w-[1024px] w-full">
                    <h2 className="font-bold text-3xl md:text-4xl text-left">
                        Crack JLPT <br />
                        with Sahasra
                    </h2>
                    <div className="flex flex-wrap mt-4 gap-6 justify-start">
                        {data.map((item, idx) => (
                            <div
                                key={idx}
                                className="flex gap-2 items-center justify-start"
                            >
                                <Image
                                    className="w-14"
                                    alt={item.name}
                                    src={item.img}
                                />
                                <span className="text-left">{item.name}</span>
                            </div>
                        ))}
                    </div>

                    <div className="mt-8 flex flex-col gap-4">
                        {discoverCources.map((item, idx) => (
                            <div
                                key={idx}
                                onClick={() => navigate("/CourseDetails/" + item.id)}
                                className={`w-full flex flex-col gap-2 ${idx % 2 === 0 ? "bg-blue-50" : "bg-gray-50"
                                    } p-6 sm:p-8 cursor-pointer`}
                            >
                                <h1 className="text-xl md:text-2xl font-bold">
                                    {item.name}
                                </h1>
                                <h1 className="text-lg md:text-xl font-semibold">
                                    {item.desc}
                                </h1>
                                <p>
                                    {item.duration} <br />
                                    {item.fee} <br />
                                    {item.level}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-10 flex justify-center">
                        <Button
                            onClick={() => window.open("https://forms.gle/2vBXEa6TyLKmvUDL8", "_blank")}
                            size="lg"
                            className="bg-primary w-full sm:w-auto"
                            radius="none"
                        >
                            <span className="text-white">Register for demo classes</span>
                        </Button>
                    </div>
                </div>

            </div>
            <AboutUs />
        </>
    );
};

export default Discover;
