import React from 'react';
import { Image } from '@nextui-org/react';
import { icons } from '../../assets';

const ClassRoom = () => {
    return (
        <div className="flex justify-center items-center flex-col p-10 bg-primary">
            <div className="flex items-center gap-3">
                {/* Logo Icon */}
                <Image
                    className="w-8"
                    radius="none"
                    src={icons.logo2}
                    alt="logo-2"
                />
                {/* Text */}
                <div className="text-center text-white">
                    <h1 className="text-2xl font-bold">Sahasra Education</h1>
                    
                </div>
            </div>
            <p className="text-white text-center text-sm">Dream It Do It</p>
        </div>
    );
};

export default ClassRoom;
