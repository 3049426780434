import React from "react";
import {
  Image,
  Navbar,
  NavbarBrand,
  NavbarMenuToggle,
  NavbarMenuItem,
  NavbarMenu,
  NavbarContent,
  NavbarItem,
  // Link
} from "@nextui-org/react";
import { icons } from "../../assets";
import { menuItems } from "../../constraints";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Navbar
      className="bg-primary text-white border-b-1 border-black"
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      shouldHideOnScroll
    >
      <NavbarContent className="sm:hidden pr-3" justify="center">
        <NavbarBrand>
          {/* <Image
            onClick={() => {
              navigate("/")
              setIsMenuOpen(false)
            }}
            className="cursor-pointer w-32"
            radius="none"
            src={icons.logo}
            alt="logo"
          /> */}

          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => {
              setIsMenuOpen(false)
              navigate("/")
            }}
          >
            {/* Logo Icon */}
            <Image
              className="w-8"
              radius="none"
              src={icons.logo2}
              alt="logo-2"
            />
            {/* Text */}
            <div className="text-center text-white">
              <h1 className="text-xl font-bold">Sahasra Education</h1>
            </div>
          </div>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-8" justify="end">
        <NavbarBrand >
          {/* <Image
           onClick={() => {
            setIsMenuOpen(false)
            navigate("/")}}
            radius="none"
            src={icons.logo}
            alt="logo"
           className="cursor-pointer w-32"
          /> */}
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => {
              setIsMenuOpen(false)
              navigate("/")
            }}
          >
            {/* Logo Icon */}
            <Image
              className="w-8"
              radius="none"
              src={icons.logo2}
              alt="logo-2"
            />
            {/* Text */}
            <div className="text-center text-white">
              <h1 className="text-xl font-bold">Sahasra Education</h1>
            </div>
          </div>
        </NavbarBrand>
        {
          menuItems.map((item, idx) => (
            <NavbarItem key={idx}>
              <Link className="text-white" to={item}>
                {item}
              </Link>
            </NavbarItem>
          ))
        }
      </NavbarContent>

      <NavbarMenu  >
        {menuItems.map((item, index) => (
          <NavbarMenuItem
            onClick={() => setIsMenuOpen(false)}
            key={`${item}-${index}`} >
            <Link
              to={item}
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
